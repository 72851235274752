import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { Link } from "react-scroll"; // scroll to sections
import { NavLinks, NavLink, PrimaryLink } from "components/headers/light";

import Picture from "components/PictureWithTag.js";
import Storyboard1 from "images/Storyboard1_1.jpg";
import Storyboard2 from "images/Storyboard1_2.jpg";
import Storyboard3 from "images/Storyboard1_3.jpg";

const Container = tw.div`relative py-32`;
const Column = tw.div`items-center flex flex-col max-w-screen-xl mx-auto`;
const FirstRow = tw.div`flex flex-row lg:items-center max-w-screen-xl mx-auto md:text-left`;
const SecondRow = tw.div`flex flex-row lg:items-center max-w-screen-xl mx-auto md:text-center sm:px-0 md:px-24 lg:px-64`;
const ThirdRow = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`;
const ForthRow = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto pt-8 `;

const Heading = tw.h1`my-8 font-black text-center text-3xl sm:text-5xl lg:text-6xl md:text-left leading-tight`;
const Paragraph = tw.p`mb-16 text-center md:text-center text-sm md:text-xl lg:text-3xl font-medium leading-relaxed text-secondary-100`;
const ButtonLeft = tw.button`mx-4 px-8 py-3 rounded-full bg-gray-300 text-gray-700 focus:outline-none hocus:bg-gray-500 hocus:text-black focus:shadow-outline border-b-0 `;
const ButtonRight = tw.button`mx-4 px-12 py-3 rounded-full bg-primary-500 text-gray-100 focus:outline-none hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline border-b-0`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

export default ({ roundedHeaderButton }) => {
  const headerLinks = [
    <NavLinks key={1}>
      <NavLink>
        <Link
          activeClass="active"
          to="section1"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          How it Works?
        </Link>
      </NavLink>
      <NavLink>
        <Link
          activeClass="active"
          to="section2"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Benefits
        </Link>
      </NavLink>
      <NavLink>
        <Link
          activeClass="active"
          to="section3"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Details
        </Link>
      </NavLink>
      <NavLink>
        <Link
          activeClass="active"
          to="section4"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Case Studies
        </Link>
      </NavLink>
      <PrimaryLink href="/" css={roundedHeaderButton && tw`rounded-full`}>
        About Us
      </PrimaryLink>
    </NavLinks>,
  ];

  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} links={headerLinks} />
      <Container>
        <Column>
          <FirstRow>
            <Heading>
              <span tw="text-primary-500 text-3xl sm:text-4xl lg:text-5xl ">
                The Interconnector
              </span>
            </Heading>
          </FirstRow>
          <SecondRow>
            <Paragraph>
              Regularly match, introduce and connect members of your community
              together for coffee chats, group activities and more. Enhance
              interconnectivity and vitalise your community!
            </Paragraph>
          </SecondRow>
          <ThirdRow>
            <Picture src={Storyboard1} tag="Customise"></Picture>
            <Picture src={Storyboard2} tag="Match"></Picture>
            <Picture src={Storyboard3} tag="Interconnect!"></Picture>
          </ThirdRow>
          <ForthRow>
            <Link
              activeClass="active"
              to="section1"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <ButtonLeft> More Info</ButtonLeft>
            </Link>
            <a
              className="link"
              href={"https://vibeeze.typeform.com/to/P3Cn1oZm"}
            >
              <ButtonRight>Build!</ButtonRight>
            </a>
          </ForthRow>
        </Column>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
