import React, { useContext, useEffect } from "react";
import firebase from "firebase";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/ClientHeader";
import Feature from "components/features/ClientInfoPage";
import Footer from "components/footers/ClientFooter.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { authContext } from "components/misc/AuthProvider";


function Participate() {
  const { user } = useContext(authContext);

  // Participate user when page loads
  useEffect(() => {
    if (user) {
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .update({ participate: true })
        .catch((err) => console.log(err));
    } else {
      console.log("no user to withdraw");
    }
  }, [user]);

  return (
    <React.Fragment>
      <Header />
      <Feature
        subheading="Participating"
        imageSrc={TeamIllustrationSrc}
        heading={
          <>
            <span tw="text-cutec-primary">You</span> have been entered for the
            next round!{" "}
          </>
        }
        description="You will receive an e-mail in within the next few days matching you with your partner."
        primaryButtonUrl="https://www.cutec.io/coffee"
      />
      <Footer />
    </React.Fragment>
  );
}
export default Participate;
