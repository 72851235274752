import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/ClientHeader.js";
import Form from "components/form/FormContainer.js";
import Footer from "components/footers/ClientFooter.js";
import Testimonial from "components/testimonials/ClientTestimonial.js";
import FAQ from "components/faqs/SingleCol.js";
import Modal from "components/modals/Modal.js";
import UseModal from "components/modals/UseModal.js";
export default () => {
  const { isShowing, toggle } = UseModal();
  return (
    <AnimationRevealPage>
      <Header />
      <Form />
      <Footer />
    </AnimationRevealPage>
  );
};
