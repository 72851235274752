import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";

import InnerCardContent from "../content/InnerCardContent.js";

const Card = styled.div`
  ${tw`flex flex-row flex-wrap mt-6 my-6 sm:max-w-2xl lg:max-w-6xl mx-auto rounded-4xl shadow-xl hover:shadow-2xl
  max-w-xs justify-center items-center border-2 border-gray-400`}
  }
  .imageContainer {
      ${tw`px-10 pb-6 my-0 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center px-6 min-w-0 w-full max-w-lg xl:max-w-3xl sm:scale-50`}
  }
  .textContainer {
    ${tw`mt-2 pr-10 pt-1 pl-8 md:pl-6 text-left flex flex-col sm:w-1/2 lg:w-1/2 `}
  }
  .title {
    ${tw`text-primary-500 sm:text-2xl lg:text-3xl mt-2 text-xl leading-none`}
  }
  .description {
    ${tw`mt-2 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`}
  }
`;

const NormalCardInnerContent = motion.custom(
  tw.dd`flex-row w-full pointer-events-none leading-relaxed pl-32 pr-8 pb-12 ease-in-out`
);

const SmallCardInnerContent = motion.custom(
  tw.dd`flex-row w-full pointer-events-none leading-relaxed pl-16 pr-8 pb-12 ease-in-out`
);

function SmallCard(props) {
  const [isOpen, setOpen] = useState(false);
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const toggleCard = () => {
    setOpen(!isOpen);
  };

  const cardVariation = {
    open: {
      opacity: 1,
      height: "auto",
      marginTop: "0px",
      marginBottom: "0rpx",
      padBottom: "80px",
    },
    collapsed: { opacity: 0, height: "0px", marginTop: "0px" },
  };

  return (
    <Card
      onClick={() => {
        toggleCard();
      }}
    >
      <span className="textContainer">
        <span className="title"> {props.title}</span>
        <p className="description"> {props.content} </p>
      </span>
      <span className="imageContainer">
        <img src={props.src} alt="" />
      </span>
      {(width < 1024 && (
        <SmallCardInnerContent
          variants={cardVariation}
          initial={isOpen === true ? "open" : "collapsed"}
          animate={isOpen === true ? "open" : "collapsed"}
          transition={{ duration: 0.28, ease: [0.54, 0.62, 0.23, 0.98] }}
        >
          <InnerCardContent
            innerContent={props.innerContent}
          ></InnerCardContent>
        </SmallCardInnerContent>
      )) || (
        <NormalCardInnerContent
          variants={cardVariation}
          initial={isOpen === true ? "open" : "collapsed"}
          animate={isOpen === true ? "open" : "collapsed"}
          transition={{ duration: 0.28, ease: [0.54, 0.62, 0.23, 0.98] }}
        >
          <InnerCardContent
            innerContent={props.innerContent}
          ></InnerCardContent>
        </NormalCardInnerContent>
      )}
    </Card>
  );
}

export default SmallCard;
