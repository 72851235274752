import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as StarIconBase } from "images/star-icon.svg";
import { ReactComponent as QuoteIconBase } from "images/quotes-l.svg";
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";
import UserIcon from "images/user-icon.png";

import "slick-carousel/slick/slick.css";

const PrimaryBackgroundContainer = tw(
  Container
)`-mx-8 px-8 bg-primary-900 text-gray-100`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;

const TestimonialsSlider = styled(Slider)`
  ${tw`flex mt-16 mx-auto max-w-xs sm:max-w-xl lg:max-w-4xl text-left bg-gray-100 rounded-lg text-gray-900`}
  .slick-track {
    ${tw`flex!`}
  }
  .slick-slide {
    ${tw`h-auto`}
  }
  .slick-slide > div {
    ${tw`h-full`}
  }
`;
const Testimonial = tw.div`px-6 py-12 sm:px-20 sm:py-16 focus:outline-none flex! flex-col justify-between h-full`;
const QuoteContainer = tw.div`relative`;
const QuoteIcon = tw(
  QuoteIconBase
)`absolute opacity-15 top-0 left-0 transform -translate-y-2 -translate-x-1/2 sm:-translate-x-full w-10 fill-current text-primary-500`;
const Quote = tw.blockquote`font-medium sm:font-normal relative text-sm sm:text-xl text-center sm:text-left`;
const CustomerInfoAndControlsContainer = tw.div`mt-8 flex items-center flex-col sm:flex-row justify-center text-center sm:text-left`;
const CustomerImage = tw.img`w-16 h-16 rounded-full`;
const CustomerNameAndProfileContainer = tw.div`mt-4 sm:mt-0 sm:ml-4 flex flex-col`;
const CustomerName = tw.span`text-lg font-semibold`;
const CustomerProfile = tw.span`text-sm font-normal text-gray-700`;
const ControlsContainer = tw.div`sm:ml-auto flex`;
const ControlButton = styled.button`
  ${tw`text-gray-600 hover:text-primary-700 focus:outline-none transition-colors duration-300 ml-4 first:ml-0 sm:first:ml-4 mt-4 sm:mt-0`}
  .icon {
    ${tw`fill-current w-6`}
  }
`;
const StarsContainer = styled.div``;
const StarIcon = tw(
  StarIconBase
)`inline-block w-5 h-5 text-orange-400 fill-current mr-1 last:mr-0`;

export default ({
  subheading = "",
  heading = "Testimonials",
  description = "Hear what some of our member have to say about us!",
  testimonials = [
    {
      stars: 5,
      customerName: "Coffee Club Member",
      imageSrc: UserIcon,
      quote:
        "I get to meet up with different people with different ideas and expectations of their future careers. It is really exciting to see so many people are so enthusiastic and full of precious knowledge of technologies",
    },
    {
      stars: 4,
      customerName: "Coffee Club Member",
      imageSrc: UserIcon,
      quote:
        "Good talk with an interesting fellow PhD student looking at innovation in the board games sector - but most of our discussion topics are generally applicable to more tech oriented ventures too.",
    },
    {
      stars: 5,
      customerName: "Coffee Club Member",
      imageSrc: UserIcon,
      quote: "Met a very helpful mentor that answered all my questions!",
    },
    {
      stars: 5,
      customerName: "Coffee Club Member",
      imageSrc: UserIcon,
      quote: "The individual was really motivated and insightful",
    },
    {
      stars: 5,
      customerName: "Coffee Club Member",
      imageSrc: UserIcon,
      quote:
        "I met someone I already know but it allowed us to connect in a different way than before.",
    },
    {
      stars: 5,
      customerName: "Coffee Club Member",
      imageSrc: UserIcon,
      quote:
        "Direct interaction with someone, good opportunity to get to know people.",
    },
    {
      stars: 5,
      customerName: "Coffee Club Member",
      imageSrc: UserIcon,
      quote: "Extremely flexible format, good quality participants.",
    },
    {
      stars: 5,
      customerName: "Coffee Club Member",
      imageSrc: UserIcon,
      quote: "Easy, convenient, and nice person. Good introduction email, and good match.",
    },
    {
      stars: 5,
      customerName: "Coffee Club Member",
      imageSrc: UserIcon,
      quote:
        "Opportunity to meet an interesting person, with shared interests, and genuine expertise",
    },
  ],
}) => {
  const [sliderRef, setSliderRef] = useState(null);

  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </HeadingContainer>
        <TestimonialsSlider arrows={false} ref={setSliderRef}>
          {testimonials.map((testimonial, index) => (
            <Testimonial key={index}>
              <QuoteContainer>
                <QuoteIcon />
                <Quote>{testimonial.quote}</Quote>
              </QuoteContainer>
              <StarsContainer>
                {Array.from({ length: testimonial.stars }).map(
                  (_, indexIcon) => (
                    <StarIcon key={indexIcon} />
                  )
                )}
              </StarsContainer>
              <CustomerInfoAndControlsContainer>
                <CustomerImage src={testimonial.imageSrc} />
                <CustomerNameAndProfileContainer>
                  <CustomerName>{testimonial.customerName}</CustomerName>
                  <CustomerProfile>
                    {testimonial.customerProfile}
                  </CustomerProfile>
                </CustomerNameAndProfileContainer>
                <ControlsContainer>
                  <ControlButton onClick={sliderRef?.slickPrev}>
                    <ArrowLeftIcon className="icon" />
                  </ControlButton>
                  <ControlButton>
                    <ArrowRightIcon
                      className="icon"
                      onClick={sliderRef?.slickNext}
                    />
                  </ControlButton>
                </ControlsContainer>
              </CustomerInfoAndControlsContainer>
            </Testimonial>
          ))}
        </TestimonialsSlider>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
