import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { Container as ContainerBase } from 'components/misc/Layouts.js'
import logo from '../../images/rithmit-logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserFriends } from '@fortawesome/free-solid-svg-icons'
import { faPeopleArrows } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as FacebookIcon } from '../../images/facebook-icon.svg'
import { ReactComponent as TwitterIcon } from '../../images/twitter-icon.svg'
import { ReactComponent as YoutubeIcon } from '../../images/youtube-icon.svg'

// const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`

const Row = tw.div`flex items-center justify-center flex-col px-8 m-1`

// const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
// const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row m-1`
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`

// const SocialLinksContainer = tw.div`mt-10`;
// const SocialLink = styled.a`
//   ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
//   svg {
//     ${tw`w-5 h-5`}
//   }
// `;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600 m-3`

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`

const Column = tw.div`md:w-1/5`
const WideColumn = tw(
  Column
)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`

const ColumnHeading = tw.h5`font-bold`

const LinkList = tw.ul`mt-4 text-sm font-medium`
const LinkListItem = tw.li`mt-3`
//const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`
const LogoImg = tw.img`w-8`
//const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `

const SocialLinksContainer = tw.div`mt-4 `
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoLink href='/'>
                <img src={logo} alt='logo' />
              Rithmit
            </LogoLink>
          </LogoContainer>
        </Row>
        <hr
          style={{
            border: '0',
            height: '1px',
            background: 'rgb(237,242,247)',
            backgroundImage:
              'linear-gradient(90deg, rgba(237,242,247,1) 21%, rgba(145,120,180,1) 50%, rgba(237,242,247,1) 81%)',
          }}
        ></hr>{' '}
        {/* <Row>
          {' '}
          <ColumnHeading>Contact</ColumnHeading>
        </Row> */}
        <Row>
          <LinksContainer>
            {/* <Link href='#'>Home</Link> */}
            {/* <Link href='#'>About</Link>
            <Link href='#'>Blog</Link> */}
            <ColumnHeading>Contact:</ColumnHeading>
            {/* <Link>
              {' '}
              <span>Phone: 07944201699</span>
            </Link> */}
            <Link href='mailto:james@rithmit.com'>james@rithmit.com</Link>

            {/* <Link href='#'>Reviews</Link> */}
          </LinksContainer>
          {/* <SocialLinksContainer>
            <SocialLink href='https://facebook.com'>
              <FacebookIcon />
            </SocialLink>
            <SocialLink href='https://twitter.com'>
              <TwitterIcon />
            </SocialLink>
            <SocialLink href='https://youtube.com'>
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer> */}
        </Row>
        <hr
          style={{
            border: '0',
            height: '1px',
            background: 'rgb(237,242,247)',
            backgroundImage:
              'linear-gradient(90deg, rgba(237,242,247,1) 21%, rgba(145,120,180,1) 50%, rgba(237,242,247,1) 81%)',
          }}
        ></hr>{' '}
        <Row>
          <CopyrightText>Reimagining online communities</CopyrightText>
        </Row>
      </Content>
    </Container>
  )
}
