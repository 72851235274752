import { getIn } from "formik";
import React from "react";
import { NativeSelect, FormControl, InputLabel } from "@material-ui/core";

export const NativeFormSelect = ({ field, form, ...props }) => {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);
  return (
    <FormControl style={{ margin: "10px auto", width: "100%", maxWidth: "400px"}}>
      <InputLabel shrink htmlFor={field.label}>
        {props.label}
      </InputLabel>
      <NativeSelect
        value={field.value}
        helperText={errorText}
        error={!!errorText}
        variant="outlined"
        {...field}
        {...props}
      />
    </FormControl>
  );
};
