import React from "react";
import tw from "twin.macro";

const Picture = tw.div`p-5 px-6 my-0 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center min-w-0 w-full max-w-lg xl:max-w-3xl`;

function PictureWithTag({ src: src, tag: tag }) {
  return (
    <Picture>
      <img src={src} alt="Design Illustration" />
      <span> {tag} </span>
    </Picture>
  );
}

export default PictureWithTag;
