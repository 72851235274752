import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import CutecLogo from "images/cutec_wide_logo.png";
import { NarrowContainer } from "components/misc/Layouts";
import { Heading } from "components/misc/Headings";

const Content = styled.div`
  ${tw`w-3/5 m-auto`}
`;
const ImageContent = styled.div`
  ${tw`mx-auto flex justify-center max-w-xl`}
`;
const VerticalSpacer = tw.div`mt-10 w-full`;

export default ({
  sectionnum = "section1", //control which section link scroll to
  heading = <>Case Studies:</>,
  imageSrc = CutecLogo,
}) => (
  <NarrowContainer id={sectionnum}>
    {heading && <Heading>{heading}</Heading>}
    <Content>
      <VerticalSpacer />
      <ImageContent>
        <a href="https://www.cutec.io/">
          <img src={imageSrc} alt="" />
        </a>
      </ImageContent>
    </Content>
  </NarrowContainer>
);
