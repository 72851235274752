import React, { useState, useLayoutEffect } from "react";
import firebase from "firebase";
import generator from "generate-password";

export const authContext = React.createContext();

const AuthProvider = (props) => {
  const [errors, setErrors] = useState(null);
  const [user, setUser] = useState(null);

  const handleSignUp = (email) => {
    var password = generator.generate({
      length: 10,
      numbers: true,
    });
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        setUser(res.user);
        return res.user;
      })
      .catch((err) => {
        console.log(err);
        setErrors(err);
      });
  };

  const handleSignIn = () => {
    if (user) {
      console.log("Using current user to login " + user.email);
      setUser(firebase.auth.currentUser);
    } else if (firebase.auth().currentUser) {
      setUser(firebase.auth.currentUser);
      console.log(
        "Using current firebase user to login " +
          firebase.auth().currentUser.email
      );
    } else if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      const params = new URLSearchParams(window.location.search);
      const email = params.get("email");
      console.log("Using e-mail link to login " + email);

      firebase
        .auth()
        .signInWithEmailLink(email)
        .then((res) => {
          setUser(res.user);
          console.log("User has been signed in: " + res.user);
        })
        .catch((err) => {
          setErrors(err);
          console.log("Error signin user in: " + err);
        });
    } else {
      console.log("No user found");
    }
  };

  const handleSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
      })
      .catch((err) => {
        setErrors(err);
        console.error(err.message);
      });
  };

  // Sign in user on every page
  useLayoutEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    });
    handleSignIn();
  }, []);

  return (
    <authContext.Provider
      value={{
        user,
        errors,
        handleSignUp,
        handleSignIn,
        handleSignOut,
      }}
    >
      {props.children}
    </authContext.Provider>
  );
};

export default AuthProvider;
