import React, { useLayoutEffect, useContext, useState } from "react";
import { Formik } from "formik";
import { Form } from "./Form";
import { string, object } from "yup";
import firebase from "firebase";
import { authContext } from "components/misc/AuthProvider";

const validationSchema = object({
  name: string("Enter a name").required("Name is required"),
  email: string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  bio: string("Enter a short bio to be shared with your matches").required(
    "Email is required"
  ),
});

const FormContainer = () => {
  const { user, errors, handleSignUp } = useContext(authContext);
  const [profile, setProfile] = useState({
    name: "",
    email: "",
    activity: "Postgraduate",
    field: "Biotechnology and Life Sciences",
    foundCompany: "I don't think that's for me",
    location: "Central Cambridge",
    bio: "",
    partnerActivity: "Undergraduate",
    partnerField: "Biotechnology and Life Sciences<",
    acquisitionSource: "Facebook",
  });
  const [isProfile, setIsProfile] = useState(false);
  const [isShowing, setIsShowing] = useState(false);

  useLayoutEffect(() => {
    if (user) {
      setIsProfile(true);
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get()
        .then((user) => {
          console.log("setting state for user: " + user);
          setProfile({
            name: user.data().name,
            email: user.data().email,
            activity: user.data().activity,
            field: user.data().field,
            foundCompany: user.data().foundCompany,
            location: user.data().location,
            bio: user.data().bio,
            partnerActivity: user.data().partnerActivity,
            partnerField: user.data().partnerField,
            acquisitionSource: user.data().acquisitionSource,
          });
        });
    } else {
      setIsProfile(false);
      console.log("no user");
    }
  }, [user, errors]);

  const handleSubmit = (values) => {
    if (user) {
      firebase.firestore().collection("users").doc(user.uid).set(values);
      setIsShowing(true);
    } else {
      handleSignUp(values.email).then((newUser) => {
        firebase.firestore().collection("users").doc(newUser.uid).set(values);
        setIsShowing(true);
      });
    }
  };

  return (
    <Formik
      render={(props) => (
        <Form
          {...props}
          isProfile={isProfile}
          isShowing={isShowing}
          hide={() => setIsShowing(false)}
        />
      )}
      initialValues={profile}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    />
  );
};

export default FormContainer;
