import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import blob from "images/double-blob.png";
import Storyboard3 from "images/Storyboard1_3.png";
import { SectionDescription } from "components/misc/Typography.js";
import { Heading } from "components/misc/Headings";
import { NarrowContainer } from "components/misc/Layouts";

const DecoratorBlob = styled.div`
  ${tw`relative bg-center w-full mx-auto my-20`}
  background-image: url(${blob});
  background-size: 100% 100%;
`;

const ImageContent = styled.div`
  ${tw`float-right`}
`;
const Content = styled.div`
  ${tw`text-left leading-10 w-3/5 pt-0 pb-24 m-auto`}
`;

const Description = tw(SectionDescription)`m-auto w-full max-w-full`;
const VerticalSpacer = tw.div`mt-10 w-full`;

const defaultPoints = [
  "🌟 Strengthen your community",
  "🌟 Realise new communities",
  "🌟 Bring people together",
  "🌟 Enhance your member's wellbeing and mental health",
  "🌟 Increase brand loyalty and event engagement",
  "🌟 Foster serendipitous innovation in your company",
  "🌟 A socially distanced alternative to mingling and networking!",
  "🌟 Indirect benefits from increasing community engagement",
  "🌟 Event turnout increases",
  "🌟 Brand and community loyalty increases",
];

export default ({
  sectionnum = "section1", //control which section link scroll to
  heading = <>The Benefits!</>,
  description = "Being part of a community means being able to meet, connect and benefit from those in it.  The interconnector helps your members increase that chance! ",
  bulletPoints = defaultPoints,
  imageSrc = Storyboard3,
}) => (
  <NarrowContainer id={sectionnum}>
    <DecoratorBlob>
      {heading && <Heading>{heading}</Heading>}
      <Content>
        <VerticalSpacer />
        <ImageContent>
          <img src={imageSrc} width="256px" height="256px" alt="" />
        </ImageContent>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {bulletPoints.map((point) => (
          <p>{point}</p>
        ))}
      </Content>
    </DecoratorBlob>
  </NarrowContainer>
);
