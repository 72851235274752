import React from "react";
import tw from "twin.macro";

import SmallCard from "../cards/SmallCard.js";
import cardsContent from "../content/cardsContent.js";
import { NarrowContainer } from "components/misc/Layouts";
import { Heading } from "components/misc/Headings";

function CardsCommunityMatcher({
  sectionnum = "section1",
  heading = <>The details:</>,
}) {
  return (
    <NarrowContainer id={sectionnum}>
      {heading && <Heading>{heading}</Heading>}
      {cardsContent.map((card) => {
        return (
          <SmallCard
            title={card.title}
            content={card.content}
            src={card.imgSrc}
            innerContent={card.innerContent}
          ></SmallCard>
        );
      })}
    </NarrowContainer>
  );
}

export default CardsCommunityMatcher;
