import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Modal from "react-modal";
import AuthProvider from "./components/misc/AuthProvider";

Modal.setAppElement("#root");

ReactDOM.render(
  <AuthProvider>
    <App />
  </AuthProvider>,
  document.getElementById("root")
);
