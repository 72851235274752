import Storyboard1 from "images/Storyboard1_1.jpg";
import Storyboard2 from "images/Storyboard1_2.jpg";
import Storyboard4 from "images/Storyboard1_4.jpg";

export const Cards = [
  {
    title: "Customised group matching",
    content:
      "Build your own signup form and determine how you match your community together.",
    imgSrc: Storyboard1,
    innerContent: [
      {
        title: "Design a matching form",
        bulletPoints: [
          "Create a signup form for members being matched[]",
          "Choose what information they give to inform matches (e.g., tick boxes, picture choices, etc.)",
          "Migrate your existing user base - so members sign in using their existing profiles specific to your community",
        ],
      },
      {
        title: "Share your matching form",
        bulletPoints: [
          "Share your signup form across digital platforms",
          "Embed it into your website",
        ],
      },
      {
        title: "Match your existing contacts and users",
        bulletPoints: [
          "Input information you already have of people to match them together (good for HR teams)",
        ],
      },
      {
        title: "Determine your type of community matchings",
        bulletPoints: [
          "Specify your matching purposes (e.g., group discussions, meeting employees across the company, networking with potential collaborators, etc.)",
          "Set when matches occur - (e.g., matches could be weekly, or they might be specific for one activity organised)",
          "Set how many people are matched (e.g., one to one matchings, or a group of people, or let your members decide)",
        ],
      },
      {
        title: "Decide your matching algorithms",
        bulletPoints: [
          "Weight the importance of how people are matched and update based on analytics)",
        ],
      },
      {
        title: "Choose how matches interact (and socially distance!)",
        bulletPoints: [
          "Set the format that matches interact (messenger, email, video, in real life)",
          "Set how interactions are scheduled, e.g., sending out a video link with a time and day",
          "Setup socially distanced matchings, arranging video group calls",
        ],
      },
    ],
  },
  {
    title: "Automated matching",
    content:
      "Automate all parts of the matching process, including when people are matched and how they are introduced to their matches.",
    imgSrc: Storyboard2,
    innerContent: [
      {
        title: "Automate the actual data matching of people together",
        bulletPoints: [
          "Automate when and how entrants for matchings are matched",
          "No need to do it manually each time!",
          "Never worry about complaints of people being rematched again!",
          "Optionally review your data matchings, before sending introductions",
          "Allow for (paid) premium members to get their first pick of their match",
        ],
      },
      {
        title: "Introduce matches automatically together",
        bulletPoints: [
          "Personalise automated introductions with our “Drag and Drop” message creator and our pre-made templates.",
          "Choose how matches are contacted (email, messenger, chatbot etc.)",
          "Scheduled a time for introductions (e.g., emails, messenger alerts) to go out on repeat, and sit back and watch the magic unfold!",
        ],
      },
      {
        title: "Schedule and arrange for matches to meet on/offline",
        bulletPoints: [
          "Send out responsive invites",
          "Automatically update member’s calendar",
        ],
      },
      {
        title:
          "Get feedback from matches, automatically informing further matchings and repeat meets!",
        bulletPoints: [
          "Find out if matches want to meet the same people again",
          "Check if members want to opt in to the next matching round",
          "Allow members to automatically arrange to chat with and see the same people again",
        ],
      },
      {
        title: "Allows your community to scale to any size - automatically!",
        bulletPoints: [
          "By having matchings, introductions, scheduling, feedback and continued matchings and meetups - you community can grow and strengthen in connection without you having to do anything!",
        ],
      },
    ],
  },
  {
    title: "Manage and analyse matching",
    content:
      "Manage how matches connect and stay in touch, and analyse the productivity of your community's matches.",
    imgSrc: Storyboard4,
    innerContent: [
      {
        title: "Learn user insights",
        bulletPoints: [
          "Developed data informed different user profiles helping you understand your usership better",
          "Integrate data insights with your current data analytics",
          "Visualise the connections being made within your community",
        ],
      },
      {
        title: "Monetise",
        bulletPoints: [
          "Setup sponsorships for your community",
          "Setup payment methods",
          "Setup advertising into your matching service",
          "Setup VIP memberships, allowing certain members to prioritise their matching",
        ],
      },
      {
        title: "Lead your community",
        bulletPoints: [
          "Determine how matches can stay in touch",
          "Send out feedback forms to inform further matches",
        ],
      },
      {
        title: "Matching manager portal",
        bulletPoints: [
          "Have your own dedicated portal, allowing you to see a top-level overview of your current matching services and producitivity",
          "Tweak and change your matching signup forms from here",
          "Update how matching occurs- customising and updating matching options",
        ],
      },
      {
        title: "GDPR assured",
        bulletPoints: [
          "Our data policy is automatically able to cover GDPR guidelines, meaning you do not need to create your own!",
          "Customise and add additions to our data policy, to fit your community needs",
        ],
      },
    ],
  },
];

export default Cards;
