import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Modal from "components/modals/Modal";
import { Field } from "formik";
import { TextFormField } from "components/form/TextFormField";
import { NativeFormSelect } from "components/form/NativeFormSelect";

const FormContainer = styled.div`
  ${tw`max-w-2xl my-16 px-8 mx-auto text-center rounded-lg shadow relative pt-2 text-gray-900 bg-white flex-col justify-center`}
  .formHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }
`;

const highlightGradientsCss = css`
  background: rgb(56, 178, 172);
  background: linear-gradient(115deg, #28235b 0%, #ffffff 100%);
`;

const FormHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-8`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest`}
  }
`;
const FormBody = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 border-t-2 border-b-2 flex-1`}
  .feature {
    ${tw`mt-5 first:mt-0 font-medium`}
    &:not(.mainFeature) {
      ${tw`text-gray-600`}
    }
  }
  .mainFeature {
    ${tw`text-xl font-bold tracking-wide`}
  }
`;

const PlanAction = tw.div`px-4 sm:px-8 xl:px-16 py-8`;
const SubmitButton = tw.button`
  rounded-full uppercase tracking-wider max-w-xs min-w-0 text-sm hover:shadow-xl 
  transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline py-3 
  font-bold bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 
  focus:shadow-outline focus:outline-none transition duration-300`;

export const Form = (props) => {
  const { handleSubmit, isValid, isProfile, isShowing, hide } = props;

  return (
    <FormContainer>
      {<div className="formHighlight" css={highlightGradientsCss} />}
      <Modal
        title={"Thank You!"}
        text={"Your profile has now been updated!"}
        isShowing={isShowing}
        hide={hide}
      ></Modal>
      <FormHeader>
        <span className="name">Your Details</span>
      </FormHeader>
      <FormBody>
        <Field label="Name" name="name" component={TextFormField} />
        <Field
          label="E-mail"
          name="email"
          disabled={isProfile}
          component={TextFormField}
        />
        <Field
          name="activity"
          label="What are you doing right now?"
          component={NativeFormSelect}
        >
          <option>Undergraduate</option>
          <option>Postgraduate</option>
          <option>Faculty/Researcher</option>
          <option>Founder</option>
          <option>Employee</option>
          <option>Investor</option>
          <option>Mentor</option>
          <option>Lawyer</option>
        </Field>
        <Field
          name="field"
          label="Describe your field"
          component={NativeFormSelect}
        >
          <option>Biotechnology and Life Sciences</option>
          <option>Physical Sciences</option>
          <option>Engineering</option>
          <option>Computer Science and Technology</option>
          <option>Finance, Management and Business</option>
        </Field>
        <Field
          name="foundCompany"
          label="Rate your eagerness to found a company"
          component={NativeFormSelect}
        >
          <option>I don't think that's for me</option>
          <option>I'm open to options</option>
          <option>I'd like to, one day</option>
          <option>I definitely want to</option>
          <option>I already have</option>
        </Field>
        <Field
          name="location"
          label="Where is convenient for you to meet?"
          component={NativeFormSelect}
        >
          <option>Central Cambridge</option>
          <option>West Cambridge</option>
          <option>North Cambridge (e.g. Science Park)</option>
          <option>Addenbrooke's</option>
        </Field>
        <Field
          label="Bio"
          name="bio"
          multiline={true}
          rows={3}
          style={{ margin: "10px auto", width: "100%", maxWidth: "500px" }}
          component={TextFormField}
        />
        <Field
          name="partnerActivity"
          label="Whom are you interested in meeting?"
          component={NativeFormSelect}
        >
          <option>Undergraduate</option>
          <option>Postgraduate</option>
          <option>Faculty/Researcher</option>
          <option>Founder</option>
          <option>Employee</option>
          <option>Investor</option>
          <option>Mentor</option>
          <option>Lawyer</option>
        </Field>
        <Field
          name="partnerField"
          label="In which fields?"
          component={NativeFormSelect}
        >
          <option>Biotechnology and Life Sciences</option>
          <option>Physical Sciences</option>
          <option>Engineering</option>
          <option>Computer Science and Technology</option>
          <option>Finance, Management and Business</option>
        </Field>
        <Field
          name="acquisitionSource"
          label="Where did you hear about us?"
          component={NativeFormSelect}
        >
          <option>Facebook</option>
          <option>Twitter</option>
          <option>E-mail</option>
          <option>Word of Mouth</option>
        </Field>
      </FormBody>
      <PlanAction>
        <SubmitButton
          type="submit"
          variant="raised"
          color="primary"
          disabled={!isValid}
          onClick={handleSubmit}
          style={{ margin: "10px 25%", width: "50%" }}
          css={highlightGradientsCss}
        >
          Submit
        </SubmitButton>
      </PlanAction>
    </FormContainer>
  );
};
