import React from "react";
import ReactDOM from "react-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton } from "components/misc/Buttons.js";

function CloseButton({ onClick }) {
  const Button = tw.button`p-1 ml-auto bg-transparent border-0 text-black opacity-100 float-right text-3xl leading-none font-semibold outline-none focus:outline-none`;
  const Span = tw.span`bg-transparent text-black opacity-100 h-6 w-6 text-2xl block outline-none focus:outline-none`;
  return (
    <Button onClick={onClick}>
      <Span>x</Span>
    </Button>
  );
}

const ModalContainer = tw.div`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none`;
const ModalContainer2 = tw.div`relative w-auto my-6 mx-auto max-w-3xl`;
const ContentContainer = tw.div`border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`;
const HeaderContainer = tw.div`flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t`;
const HeaderText = tw.h3`text-3xl font-semibold`;
const BodyContainer = tw.div`relative p-6 flex-auto`;
const ModalText = tw.p`my-4 text-gray-600 text-lg leading-relaxed`;
const FooterContainer = tw.div`flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b`;
const CancelButton = tw.button`text-red-500 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1`;
const SaveButton = tw.button`bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`;

const Modal = ({
  title = "Title",
  text = "Text",
  cancelButtonText = "Close",
  confirmButtonText = "Okay",
  isShowing,
  hide,
}) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <ModalContainer onClick={hide}>
            <ModalContainer2>
              {/*content*/}
              <ContentContainer>
                {/*header*/}
                <HeaderContainer>
                  <HeaderText>{title}</HeaderText>
                  <CloseButton onClick={hide} />
                </HeaderContainer>
                {/*body*/}
                <BodyContainer>
                  <ModalText>{text}</ModalText>
                </BodyContainer>
                {/*footer*/}
                <FooterContainer>
                  <CancelButton onClick={hide}>{cancelButtonText}</CancelButton>
                  <SaveButton onClick={hide}>{confirmButtonText}</SaveButton>
                </FooterContainer>
              </ContentContainer>
            </ModalContainer2>
          </ModalContainer>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </React.Fragment>,
        document.body
      )
    : null;

export default Modal;

// export default ({
//   buttonText = "ButtonText",
//   title = "Title",
//   text = "Text",
//   cancleButtonText = "Close",
//   confirmButtonText = "Okay",
// }) => {
//   const [showModal, setShowModal] = React.useState(false);

//   const ModalContainer = tw.div`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none`;
//   const ModalContainer2 = tw.div`relative w-auto my-6 mx-auto max-w-3xl`;
//   const ContentContainer = tw.div`border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`;
//   const HeaderContainer = tw.div`flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t`;
//   const HeaderText = tw.h3`text-3xl font-semibold`;
//   const BodyContainer = tw.div`relative p-6 flex-auto`;
//   const ModalText = tw.p`my-4 text-gray-600 text-lg leading-relaxed`;
//   const FooterContainer = tw.div`flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b`;
//   const CancelButton = tw.button`text-red-500 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1`;
//   const SaveButton = tw.button`bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`;

//   return (
//     <React.Fragment>
//       <PrimaryButton
//         className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 transition-all duration-150 ease-in-out"
//         type="button"
//         style={{ transition: "all .15s ease" }}
//         onClick={() => setShowModal(true)}
//       >
//         {buttonText}
//       </PrimaryButton>

//       {showModal ? (
//         <>
//           <ModalContainer onClick={() => setShowModal(false)}>
//             <ModalContainer2>
//               {/*content*/}
//               <ContentContainer>
//                 {/*header*/}
//                 <HeaderContainer>
//                   <HeaderText>{title}</HeaderText>
//                   <CloseButton onClick={() => setShowModal(false)} />
//                 </HeaderContainer>
//                 {/*body*/}
//                 <BodyContainer>
//                   <ModalText>{text}</ModalText>
//                 </BodyContainer>
//                 {/*footer*/}
//                 <FooterContainer>
//                   <CancelButton onClick={() => setShowModal(false)}>
//                     {cancleButtonText}
//                   </CancelButton>
//                   <SaveButton onClick={() => setShowModal(false)}>
//                     {confirmButtonText}
//                   </SaveButton>
//                 </FooterContainer>
//               </ContentContainer>
//             </ModalContainer2>
//           </ModalContainer>
//           <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
//         </>
//       ) : null}
//     </React.Fragment>
//   );
// };
