import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";

const InnerTitle = tw.li`mt-2 text-secondary-500 md:text-xl lg:text-xl sm:text-sm font-medium list-disc`;

const Points = tw.div`p-2 sm:text-xs lg:text-lg text-xs`;
const listItems = (bulletPoints) => bulletPoints.map((item) => <li>{item}</li>);

function InnerCardContent({ innerContent: innerContent }) {
  return innerContent.map((section) => {
    return (
      <ul>
        <li>
          <InnerTitle>{section.title}</InnerTitle>
        {/* <Points>{listItems(section.bulletPoints)}</Points> */}
        </li>
      </ul>
    );
  });
}

export default InnerCardContent;
