import { getIn } from "formik";
import React from "react";
import { TextField } from "@material-ui/core";

export const TextFormField = ({ field, form, ...props }) => {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);
  return (
    <TextField
      onChange={form.change}
      value={field.value}
      helperText={errorText}
      error={!!errorText}
      variant="outlined"
      style={{ margin: "10px auto", width: "100%", maxWidth: "250px"}}
      {...field}
      {...props}
    />
  );
};
