import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Heading } from "components/misc/Headings";
import { NarrowContainer } from "components/misc/Layouts";

const Content = styled.div`
  ${tw`text-left leading-10 w-full m-auto ml-10`}
`;

const MainTextNumbers = tw.span`text-secondary-100 text-xl md:text-4xl`;
const MainText = tw.span`text-secondary-100 text-lg md:text-2xl leading-tight m-auto pr-10`;
const TableData = tw.td`py-2 px-2`;

const defaultPoints = [
  "Members sign up or you upload your network’s details for regular matchings",
  "Members are regularly paired or grouped together based on matching preferences (size, purpose, personality, etc)",
  "Matched groups and pairs are introduced and scheduled for video chats or real life meets - using existent communication services (email, messenger, social, etc)",
];

export default ({
  sectionnum = "section1", //control which section link scroll to
  heading = <>How it works:</>,
  bulletPoints = defaultPoints,
}) => (
  <NarrowContainer id={sectionnum}>
    {heading && <Heading>{heading}</Heading>}
    <Content>
      <MainText>
        <table>
          {bulletPoints.map((point, index) => (
            <tr>
              <TableData>
                <MainTextNumbers>{(index + 1).toString()}.</MainTextNumbers>
              </TableData>
              <TableData>
                <MainText>{point}</MainText>
              </TableData>
            </tr>
          ))}
        </table>
      </MainText>
    </Content>
  </NarrowContainer>
);
