import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import FooterTwo from "components/footers/MiniCenteredFooter";
import Hero from "components/hero/MatcherTop.js";
import CardFeature from "components/features/CardsCommunityMatcher.js";
import PlainTextFeature from "components/features/PlainTextInfo.js";
import BlobFeature from "components/features/BlobInfoPage.js";
import UserStudy from "components/features/SimpleUserStudies.js";

export default () => {
  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <PlainTextFeature sectionnum="section1" />
      <BlobFeature sectionnum="section2" />
      <CardFeature sectionnum="section3" />
      <UserStudy sectionnum="section4" />
      <FooterTwo />
    </AnimationRevealPage>
  );
};
