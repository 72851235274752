import React, { useEffect, useContext } from "react";
import firebase from "firebase";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/ClientHeader";
import Feature from "components/features/ClientInfoPage";
import Footer from "components/footers/ClientFooter.js";
import { authContext } from "components/misc/AuthProvider";


function Withdraw() {
  const { user } = useContext(authContext);

  // Delete user when page loads
  useEffect(() => {
    if (user) {
      user.delete().then(() => {
        firebase.firestore().collection("users").doc(user.uid).delete();
      });
    } else {
      console.log("no user to withdraw");
    }
  }, [user]);

  return (
    <React.Fragment>
      <Header />
      <Feature
        subheading="Withdrawing"
        heading={
          <>
            <span tw="text-cutec-primary">You</span> have been withdrawn
          </>
        }
        description="You have now been withdrawn and your account has been deleted, if you would like to rejoin please use the link below."
        primaryButtonText="Rejoin"
        primaryButtonUrl="https://www.cutec.io/coffee"
      />
      <Footer />
    </React.Fragment>
  );
}

export default Withdraw;
