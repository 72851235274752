import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
//eslint-disable-next-line
import { css } from 'styled-components/macro'

import Header from '../headers/light.js'

import { ReactComponent as SvgDecoratorBlob1 } from '../../images/svg-decorator-blob-1.svg'
import DesignIllustration from '../../images/undraw_content_team_3epn.png'
import CustomersLogoStripImage from '../../images/customers-logo-strip.png'

const Container = tw.div`relative`
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`

const Heading = tw.h1`mt-4 font-black text-left text-3xl sm:text-5xl lg:text-6xl text-center md:text-left leading-tight`
const Paragraph = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`

export default ({ roundedHeaderButton }) => {
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              <span tw='text-primary-500 text-3xl sm:text-4xl lg:text-5xl '>
                Reimagining online communities                
              </span>
            </Heading>
            <Paragraph >
              We are a team of recent and final year graduates at the University
              of Cambridge. We are conceiving a new way for communities and
              people to connect online.
            </Paragraph>
            <p>&nbsp;</p>
            <p>&nbsp;</p>

            <Actions>
              <form
                method='post'
                action='https://api.formcake.com/api/form/98a18774-aa61-4fbb-bf70-780d97ad45d6/submission'
              >
                <input
                  name='email'
                  type='email'
                  required='required'
                  placeholder='Your E-mail Address'
                />
                <button type='submit'>Update me</button>
              </form>
            </Actions>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            {/* <CustomersLogoStrip>
              <p>Our TRUSTED Customers</p>
              <img src={CustomersLogoStripImage} alt='Our Customers' />
            </CustomersLogoStrip> */}
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                tw='min-w-0 w-full max-w-lg xl:max-w-3xl'
                src={DesignIllustration}
                alt='Design Illustration'
              />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  )
}
