import app from 'firebase/app';
const config = {
    apiKey: "AIzaSyCoMP32K5frhM9nVK0Bg3jEPXZrfcKfTZ4",
    authDomain: "rithmit.firebaseapp.com",
    databaseURL: "https://rithmit.firebaseio.com",
    projectId: "rithmit",
    storageBucket: "rithmit.appspot.com",
    messagingSenderId: "28502306908",
    appId: "1:28502306908:web:5ce00f9cca8e911dbbf699",
    measurementId: "G-XNXE8H5HWQ"
};
app.initializeApp(config)

export default app;